import axios from 'axios'

const useAddYoloV8 = () => {
    const postyoloV8 = (postData, setResponse) => {
        console.log("UseAddYoloV8 Werkt", postData)
        axios.post(`manifest/triggeryolo/${postData}`, postData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        }).then((response) => {
            setResponse(response.data)
        })
    }

    return postyoloV8
}

export default useAddYoloV8