import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import useUpdateRegistration from '../hooks/useUpdateRegistration';
import useAddYoloV8 from '../hooks/useAddYoloV8';
import useAddRegistration from '../hooks/useAddRegistration';
import useGetRegistration from '../hooks/useGetRegistration';
import MiradorViewer from '../components/MiradorViewer';
import formsconfig from '../forms/formconfig';
import Modal from 'react-modal';
import '../App.css';

function SchadeRapportEditorBoris() {
    const [formConfig, setFormConfig] = useState(null);
    const [selectedTags, setSelectedTags] = useState({});
    const { objectid } = useParams();
    const postRegistration = useAddRegistration();
    const YoloV8Annotation = useAddYoloV8();
    const updateRegistration = useUpdateRegistration(objectid)
    const [response, setResponse] = useState()
    const [firstTime, setFirstTime] = useState(true)
    const [submitModal, setSubmitModal] = useState(false)
    console.log(objectid)

    const [Registratie, setRegistratie] = useState({
        id: objectid,
        name: null,
        function: null,
        reason: null,
        version: 0,
        remarks: null,
        identification: {
            id: uuidv4(),
            invetoryNumber: 0,
            artist: {
                id: uuidv4(),
                surName: null,
                firstName: null,
            },
            titleDutch: null,
            titleFrench: null,
            date: {
                id: uuidv4(),
                text: null,
                location: null,
                media: null,
                value: null,
            },
            signature: {
                id: uuidv4(),
                text: null,
                location: null,
                media: null,
                value: null,
            },
            inscriptions: [],
            collectionMarkAvailable: null,
            collectionMarks: [],
            materials: null,
            techniques: null,
            support: null,
            //framed: [null],
            framedSerialized: null,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
        },
        primarySupport: {
            id: uuidv4(),
            based: null,
            material: [null],
            materialSerialized: null,
            paperType1: null,
            paperType2: null,
            paperType3: [null],
            assemblage: null,
            rectoVerso: null,
            watermark: null,
            watermarkDescription: null,
            watermarkLocation: null,
            pinholed: null,
            amount: 0,
            location: null,
            remarksDescription: null,
            generalCondition: null,
            surface: [null],
            surfaceSerialized: null,
            damage: [null],
            damageSerialized: null,
            remarksCondition: null,
            pictorial: {
                id: uuidv4(),
                friableMedia: [null],
                friableMediaSerialized: null,
                fixative: null,
                fixativeIndicators: null,
                otherMedia: [null],
                otherMediaSerialized: null,
                techniques: [null],
                techniquesSerialized: null,
                remarksDescription: null,
                generalCondition: null,
                surface: [null],
                damage: [null],
                damageSerialized: null,
                remarksCondition: null,
            },
        },
        secondarySupport: {
            id: uuidv4(),
            supportAccessible: null,
            based: null,
            material: [null],
            materialSerialized: null,
            paperType1: null,
            paperType2: null,
            paperType3: null,
            assemblage: null,
            rectoVerso: null,
            watermark: null,
            watermarkDescription: null,
            watermarkLocation: null,
            pinholed: null,
            amount: 0,
            location: null,
            remarksDescription: null,
            generalCondition: null,
            surface: [null],
            damage: [null],
            damageSerialized: null,
            remarksCondition: null,
            attachment: null,
            pictorial: {
                id: uuidv4(),
                pictorialAccessible: null,
                friableMedia: [null],
                friableMediaSerialized: null,
                fixative: null,
                fixativeIndicators: null,
                otherMedia: [null],
                otherMediaSerialized: null,
                techniques: [null],
                techniquesSerialized: null,
                remarksDescription: null,
                generalCondition: null,
                surface: [null],
                damage: [null],
                damageSerialized: null,
                remarksCondition: null,
            },
        },
        storage: {
            id: uuidv4(),
            location: null,
            locationNumber: null,
            storageType: null,
            material: [null],
            materialSerialized: null,
            remarks: null,
        },
        mounting: {
            id: uuidv4(),
            nature: [null],
            assemblage: [null],
            generalCondition: null,
            surface: [null],
            damage: [null],
            damageSerialized: null,
            myProperty: null,
            descriptionRemarks: null,
            conditionRemarks: null,
        },
        framing: {
            id: uuidv4(),
            height: 0,
            width: 0,
            depth: 0,
            shape: null,
            damageRelevant: null,
            appertureFrameHeight: 0,
            apertureFrameWidth: 0,
            apretureMountHeight: 0,
            apertureMountWidth: 0,
            maxPaintingDimensionsHeight: 0,
            maxPaintingDimensionsWidth: 0,
            rabbetAccessible: null,
            rabbetHeight: 0,
            rabbetWidth: 0,
            rabbetDepth: 0,
            innerSpacersHeight: 0,
            innerSpacersWidth: 0,
            innerSpacersDepth: 0,
            externalRisingSticksHeight: 0,
            externalRisingSticksWidth: 0,
            externalRisingSticksDepth: 0,
            dimensionRemarks: null,
            mouldingMaterial: null,
            mouldingAssemblage: null,
            raisingSticksMaterial: null,
            raisingSticksAssemblage: null,
            innerSpacers1: [null],
            innerSpacers2: [null],
            backingBoard1: [null],
            backingBoard2: [null],
            sealing: [null],
            hangingSystem: null,
            indicators: null,
            descriptionRemarks: null,
            material: null,
            assemblage: null,
            glazingHeight: 0,
            glazingWidth: 0,
            glazingEstimation: null,
            glazingRemarks: null,
            originalFraming: null,
            historyIndicators: null,
            labelsAmount: 0,
            historyDescription: null,
            surface: [null],
            mechanicalProblems: [null],
            mechanicalProblemsSerialized: null,
            chemicalProblems: [null],
            chemicalProblemsSerialized: null,
            biologicalProblems: [null],
            biologicalProblemsSerialized: null,
            aestheticProblems: [null],
            aestheticProblemsSerialized: null,
        },
    })

    useEffect(() => console.log(Registratie), [Registratie])
    const { loading, error, element } = useGetRegistration(objectid)



    useEffect(() => {
        setFormConfig(formsconfig);
        // Initialize selectedTags state
        const initialSelectedTags = {};
        formsconfig.fields.forEach(field => {
            if (field.type === 'tags') {
                initialSelectedTags[field.name] = []; // Initialize as an empty array
            }
        });
        setSelectedTags(initialSelectedTags);
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();  // This will prevent the default form submit action, which is to reload the page
        if (firstTime) {
            postRegistration(Registratie, setResponse)
        } else {
            updateRegistration(Registratie, setResponse)
        }
    };

    const handleYoloV8 = (event) => {
        event.preventDefault();  // This will prevent the default form submit action, which is to reload the page
        YoloV8Annotation(objectid, setResponse)
        console.log(objectid)
    };

    const handleTagToggle = (fieldName, tagValue) => {
        // Update selectedTags to contain only the selected tagValue
        setSelectedTags(prev => ({ ...prev, [fieldName]: tagValue }));

        // Update Registratie with the new tag
        setRegistratie(prevReg => ({
            ...prevReg,
            identification: {
                ...prevReg.identification,
                titleDutch: tagValue
            }
        }));
    };



    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start', height: 'auto', paddingTop: '100px' }}>
            <MiradorViewer id={objectid} style={{ width: 'calc(60% - 10px)', height: 'auto', marginRight: '20px' }} />
            <div style={{ width: '40%', display: 'flex', flexDirection: 'column', padding: '20px', boxSizing: 'border-box' }}>
                {/* Header with Schaderapport, Last Updated, and Inventory Number */}
                <div style={{ marginBottom: '20px' }}>
                    <h2 style={{ display: 'block', marginBottom: '5px' }}>
                        Schaderapport: {Registratie.identification.titleDutch}
                    </h2>
                    <span style={{ display: 'block', marginBottom: '5px' }}>
                        Last updated: {new Date().toLocaleDateString()} // Replace with actual last updated logic
                    </span>
                    <span style={{ display: 'block', marginBottom: '5px' }}>
                        Inventory Number: {objectid}
                    </span>
                </div>
                {formConfig ? (
                    <form onSubmit={handleSubmit} style={{ backgroundColor: '#f5f5f5', padding: '20px', borderRadius: '8px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        {formConfig.fields.map(field => (
                            <div key={field.name} style={{ marginBottom: '20px' }}>
                                <label htmlFor={field.name} style={{ display: 'block', marginBottom: '5px', fontWeight: 'bold' }}>{field.label}</label>
                                {field.type === 'tags' ? (
                                    <div>
                                        {field.options.map(option => (
                                            <button
                                                key={option.value}
                                                type="button"
                                                style={{
                                                    margin: '0 5px',
                                                    padding: '10px',
                                                    backgroundColor: selectedTags[field.name] === option.value ? '#007bff' : '#f5f5f5',
                                                    color: selectedTags[field.name] === option.value ? '#fff' : '#000',
                                                    border: '1px solid #ccc'
                                                }}
                                                onClick={() => handleTagToggle(field.name, option.value)}
                                            >
                                                {option.label}
                                            </button>
                                        ))}
                                    </div>
                                ) : (
                                    <input
                                        type={field.type}
                                        id={field.name}
                                        name={field.name}
                                        required={field.required}
                                        style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
                                    />
                                )}
                            </div>
                        ))}
                        <button type="submit" style={{ backgroundColor: '#007bff', color: '#fff', padding: '10px 20px', borderRadius: '4px', border: 'none', cursor: 'pointer' }}>Submit</button>
                        <button type="button" style={{ backgroundColor: '#007bff', color: '#fff', padding: '10px 20px', borderRadius: '4px', border: 'none', cursor: 'pointer' }}
                            onClick={handleYoloV8}>Automatische gezichtherkenning
                        </button>
                    </form>
                ) : (
                    <p>Loading form...</p>
                )}
            </div>
        </div>
    );

}

export default SchadeRapportEditorBoris;