import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import axios from 'axios'

// axios.defaults.baseURL =
//     // 'https://friablemongo20230424170902.azurewebsites.net/api/'

axios.defaults.baseURL = 'https://10.10.160.94:7148/api/'
// axios.defaults.baseURL = 'https://10.10.160.94:444/api/'

axios.defaults.headers.post['Content-Type'] = 'application/json'

// const root = ReactDOM.createRoot(document.getElementById('root'))
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
