import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import RectangleIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CircleIcon from '@material-ui/icons/RadioButtonUnchecked'
import PolygonIcon from '@material-ui/icons/Timeline'
import GestureIcon from '@material-ui/icons/Gesture'
import ClosedPolygonIcon from '@material-ui/icons/ChangeHistory'
import OpenPolygonIcon from '@material-ui/icons/ShowChart'
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill'
import StrokeColorIcon from '@material-ui/icons/BorderColor'
import LineWeightIcon from '@material-ui/icons/LineWeight'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import FormatShapesIcon from '@material-ui/icons/FormatShapes'
import Popover from '@material-ui/core/Popover'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuList from '@material-ui/core/MenuList'
import { SketchPicker } from 'react-color'
import { v4 as uuid } from 'uuid'
import { withStyles } from '@material-ui/core/styles'
import CompanionWindow from 'mirador/dist/es/src/containers/CompanionWindow'
import AnnotationDrawing from './AnnotationDrawing'
import WebAnnotation from './WebAnnotation'
import CursorIcon from './icons/Cursor'

/** */
class AnnotationCreation extends Component {
    /** */
    constructor(props) {
        super(props)
        this.dropdown = React.createRef()
        const annoState = {}
        if (props.annotation) {
            if (Array.isArray(props.annotation.body)) {
                annoState.tags = []
                props.annotation.body.forEach((body) => {
                    if (body.purpose === 'tagging') {
                        annoState.tags.push(body.value)
                    } else {
                        annoState.annoBody = body.value.split()
                    }
                })
            } else {
                annoState.annoBody = props.annotation.body.value.split(':')[0]
                annoState.annoBody2 = props.annotation.body.value.split(':')[1]
                annoState.annoBody3 = props.annotation.body.value.split(':')[2]
            }
            if (props.annotation.target.selector) {
                if (Array.isArray(props.annotation.target.selector)) {
                    props.annotation.target.selector.forEach((selector) => {
                        if (selector.type === 'SvgSelector') {
                            annoState.svg = selector.value
                        } else if (selector.type === 'FragmentSelector') {
                            annoState.xywh = selector.value.replace('xywh=', '')
                        }
                    })
                } else {
                    annoState.svg = props.annotation.target.selector.value
                }
            }
        }

        const toolState = {
            activeTool: 'cursor',
            closedMode: 'closed',
            currentColorType: false,
            fillColor: null,
            strokeColor: '#00BFFF',
            strokeWidth: 3,
            ...(props.config.annotation.defaults || {}),
        }

        this.state = {
            ...toolState,
            annoBody: '',
            annoBody2: '',
            annoBody3: '',
            annoBody4: '',
            colorPopoverOpen: false,
            lineWeightPopoverOpen: false,
            popoverAnchorEl: null,
            popoverLineWeightAnchorEl: null,
            svg: null,
            textEditorStateBustingKey: 0,
            xywh: null,
            ...annoState,
            searchInput: '',
        }

        this.submitForm = this.submitForm.bind(this)
        this.updateBody = this.updateBody.bind(this)
        this.updateGeometry = this.updateGeometry.bind(this)
        this.changeTool = this.changeTool.bind(this)
        this.changeClosedMode = this.changeClosedMode.bind(this)
        this.openChooseColor = this.openChooseColor.bind(this)
        this.openChooseLineWeight = this.openChooseLineWeight.bind(this)
        this.handleLineWeightSelect = this.handleLineWeightSelect.bind(this)
        this.handleCloseLineWeight = this.handleCloseLineWeight.bind(this)
        this.closeChooseColor = this.closeChooseColor.bind(this)
        this.updateStrokeColor = this.updateStrokeColor.bind(this)
        this.handleDamage = this.handleDamage.bind(this)
        this.handleDamage2 = this.handleDamage2.bind(this)
        this.handleDamage3 = this.handleDamage3.bind(this)
        this.locations = {
            Abrasion: 'Both',
            Accretions: 'Pictorial',
            'Acidic material': 'Support',
            'Adhesif residues': 'Support',
            Aglomerat: 'Support',
            Bleeding: 'Pictorial',
            Blister: 'Pictorial',
            Break: 'Support',
            Brittleness: 'Support',
            'Burned area': 'Support',
            Cleavage: 'Support',
            Cracks: 'Both',
            Crease: 'Support',
            Cut: 'Support',
            Deformation: 'Support',
            Dents: 'Both',
            Discolorations: 'Both',
            Dust: 'Both',
            Embrittlement: 'Support',
            Fading: 'Both',
            'Finger stain': 'Support',
            Flaking: 'Pictorial',
            Fold: 'Support',
            'Former conservation trace': 'Support',
            Foxing: 'Support',
            Grime: 'Support',
            Hole: 'Support',
            Inclusion: 'Both',
            Inpainting: 'Pictorial',
            'Insect damage': 'Support',
            'Lifted area/lifting': 'Both',
            Loss: 'Both',
            'Matted area': 'Both',
            'Missing part': 'Support',
            Mold: 'Support',
            'Old repair': 'Support',
            Ondulation: 'Support',
            'Open corner': 'Support',
            Oxydation: 'Both',
            'Packing residues': 'Support',
            'Rusty stain': 'Support',
            Scratch: 'Both',
            'Shiny spot': 'Both',
            Skinning: 'Support',
            Smudging: 'Pictorial',
            'Spider web': 'Support',
            Stain: 'Support',
            Tear: 'Support',
            Tension: 'Support',
            'Traces of use': 'Support',
            Transfer: 'Pictorial',
            'Waterstain/tideline': 'Both',
            Waving: 'Support',
            Whitening: 'Both',
            Wrinkling: 'Support',
            Yellowing: 'Both',
        }
    }

    /** */
    handleCloseLineWeight(e) {
        this.setState({
            lineWeightPopoverOpen: false,
            popoverLineWeightAnchorEl: null,
        })
    }

    /** */
    handleLineWeightSelect(e) {
        this.setState({
            lineWeightPopoverOpen: false,
            popoverLineWeightAnchorEl: null,
            strokeWidth: e.currentTarget.value,
        })
    }

    /** */
    openChooseColor(e) {
        this.setState({
            colorPopoverOpen: true,
            currentColorType: e.currentTarget.value,
            popoverAnchorEl: e.currentTarget,
        })
    }

    /** */
    openChooseLineWeight(e) {
        this.setState({
            lineWeightPopoverOpen: true,
            popoverLineWeightAnchorEl: e.currentTarget,
        })
    }

    /** */
    closeChooseColor(e) {
        this.setState({
            colorPopoverOpen: false,
            currentColorType: null,
            popoverAnchorEl: null,
        })
    }

    /** */
    updateStrokeColor(color) {
        const { currentColorType } = this.state
        this.setState({
            [currentColorType]: color.hex,
        })
    }

    /** */
    submitForm(e) {
        e.preventDefault()
        const { annotation, canvases, receiveAnnotation, config } = this.props
        const {
            annoBody,
            tags,
            xywh,
            svg,
            textEditorStateBustingKey,
            annoBody2,
            annoBody3,
        } = this.state
        canvases.forEach((canvas) => {
            const storageAdapter = config.annotation.adapter(canvas.id)
            const anno = new WebAnnotation({
                body: annoBody + ':' + annoBody2 + ':' + annoBody3,
                canvasId: canvas.id,
                id: (annotation && annotation.id) || `${uuid()}`,
                manifestId: canvas.options.resource.id,
                svg,
                tags,
                xywh,
            }).toJson()
            if (annotation) {
                storageAdapter.update(anno).then((annoPage) => {
                    receiveAnnotation(
                        canvas.id,
                        storageAdapter.annotationPageId,
                        annoPage,
                    )
                })
            } else {
                storageAdapter.create(anno).then((annoPage) => {
                    receiveAnnotation(
                        canvas.id,
                        storageAdapter.annotationPageId,
                        annoPage,
                    )
                })
            }
        })

        this.setState({
            annoBody: '',
            annoBody2: '',
            annoBody3: '',
            svg: null,
            textEditorStateBustingKey: textEditorStateBustingKey + 1,
            xywh: null,
        })
        this.props.closeCompanionWindow()
    }

    /** */
    changeTool(e, tool) {
        this.setState({
            activeTool: tool,
        })
    }

    /** */
    changeClosedMode(e) {
        this.setState({
            closedMode: e.currentTarget.value,
        })
    }

    /** */
    updateBody(annoBody4) {
        this.setState({ annoBody4 })
    }

    /** */
    updateGeometry({ svg, xywh }) {
        this.setState({
            svg,
            xywh,
        })
    }

    /** */
    handleDamage(event) {
        this.setState({
            annoBody: event.target.value,
        })
        let color = '#FF7F50'
        if (event.target.value === 'Aesthetic') {
            color = '#98FB98'
        } else if (event.target.value === 'Biological') {
            color = '#E6E6FA'
        } else if (event.target.value === 'Chemical') {
            color = '#FFDB58'
        }

        this.setState({
            strokeColor: color,
        })
    }

    /** */
    handleDamage2(event) {
        this.setState({
            annoBody2: event.target.value,
            annoBody3: this.locations[event.target.value],
        })
    }

    /** */
    handleDamage3(event) {
        this.setState({
            annoBody3: event.target.value,
        })
    }

    /** */
    render() {
        const { annotation, classes, closeCompanionWindow, id, windowId } =
            this.props

        const {
            activeTool,
            colorPopoverOpen,
            currentColorType,
            fillColor,
            popoverAnchorEl,
            strokeColor,
            popoverLineWeightAnchorEl,
            lineWeightPopoverOpen,
            strokeWidth,
            closedMode,
            annoBody,
            svg,
            annoBody2,
        } = this.state

        const damages = {
            Aesthetic: [
                'Bleeding',
                'Blister',
                'Discolorations',
                'Dust',
                'Inpainting',
                'Matted area',
                'Shiny spot',
                'Stain',
                'Whitening',
                'Yellowing',
            ],
            Biological: ['Insect damage', 'Mold', 'Spider web'],
            Chemical: [
                'Accretions',
                'Acidic material',
                'Adhesive residues',
                'Burned area',
                'Fading',
                'Finger stain',
                'Former conservation trace',
                'Foxing',
                'Grime',
                'Oxydation',
                'Rusty stain',
                'Waterstain/tideline',
            ],
            Mechanical: [
                'Abrasion',
                'Break',
                'Cleavage',
                'Cracks',
                'Crease',
                'Cut',
                'Deformation',
                'Dents',
                'Flaking',
                'Fold',
                'Hole',
                'Lifted area/lifting',
                'Loss',
                'Missing part',
                'Old repair',
                'Ondulation',
                'Open corner',
                'Packing residues',
                'Scratch',
                'Skinning',
                'Smudging',
                'Tear',
                'Tension',
                'Traces of use',
                'Transfer',
                'Waving',
                'Wrinkling',
                'Aglomerat',
                'Inclusion',
                'Britleness',
                'Embrittlement',
            ],
        }

        return (
            <CompanionWindow
                title={annotation ? 'Edit annotation' : 'New annotation'}
                windowId={windowId}
                id={id}
            >
                <AnnotationDrawing
                    activeTool={activeTool}
                    fillColor={fillColor}
                    strokeColor={strokeColor}
                    strokeWidth={strokeWidth}
                    closed={closedMode === 'closed'}
                    svg={svg}
                    updateGeometry={this.updateGeometry}
                    windowId={windowId}
                />
                <form onSubmit={this.submitForm} className={classes.section}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="overline">Category</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <select
                                value={annoBody}
                                onChange={this.handleDamage}
                            >
                                <option>Select an option</option>
                                <option value="Aesthetic">Aesthetic</option>
                                <option value="Biological">Biological</option>
                                <option value="Chemical">Chemical</option>
                                <option value="Mechanical">Mechanical</option>
                            </select>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="overline">Damage</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <select
                                id="dropdown"
                                value={annoBody2}
                                onChange={this.handleDamage2}
                                ref={this.dropdown}
                                disabled={!annoBody}
                                className="mb-5"
                            >
                                <option>Select an option</option>
                                {annoBody
                                    ? damages[annoBody].map((damage) => (
                                          <option key={damage} value={damage}>
                                              {damage}
                                          </option>
                                      ))
                                    : ' '}
                            </select>
                        </Grid>
                        {/* Extra remarks
                        <Grid item xs={12}>
                            <TextEditor
                                key={textEditorStateBustingKey}
                                annoHtml={annoBody4}
                                updateAnnotationBody={this.updateBody}
                            />
                        </Grid> */}
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="overline">Target</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper elevation={0} className={classes.paper}>
                                <ToggleButtonGroup
                                    className={classes.grouped}
                                    value={activeTool}
                                    exclusive
                                    onChange={this.changeTool}
                                    aria-label="tool selection"
                                    size="small"
                                >
                                    <ToggleButton
                                        value="cursor"
                                        aria-label="select cursor"
                                    >
                                        <CursorIcon />
                                    </ToggleButton>
                                    <ToggleButton
                                        value="edit"
                                        aria-label="select cursor"
                                    >
                                        <FormatShapesIcon />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                                <Divider
                                    flexItem
                                    orientation="vertical"
                                    className={classes.divider}
                                />
                                <ToggleButtonGroup
                                    className={classes.grouped}
                                    value={activeTool}
                                    exclusive
                                    onChange={this.changeTool}
                                    aria-label="tool selection"
                                    size="small"
                                >
                                    <ToggleButton
                                        value="rectangle"
                                        aria-label="add a rectangle"
                                    >
                                        <RectangleIcon />
                                    </ToggleButton>
                                    <ToggleButton
                                        value="ellipse"
                                        aria-label="add a circle"
                                    >
                                        <CircleIcon />
                                    </ToggleButton>
                                    <ToggleButton
                                        value="polygon"
                                        aria-label="add a polygon"
                                    >
                                        <PolygonIcon />
                                    </ToggleButton>
                                    <ToggleButton
                                        value="freehand"
                                        aria-label="free hand polygon"
                                    >
                                        <GestureIcon />
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant="overline">Style</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <ToggleButtonGroup
                                aria-label="style selection"
                                size="small"
                            >
                                <ToggleButton
                                    value="strokeColor"
                                    aria-label="select color"
                                    onClick={this.openChooseColor}
                                >
                                    <StrokeColorIcon
                                        style={{ fill: strokeColor }}
                                    />
                                    <ArrowDropDownIcon />
                                </ToggleButton>
                                <ToggleButton
                                    value="strokeColor"
                                    aria-label="select line weight"
                                    onClick={this.openChooseLineWeight}
                                >
                                    <LineWeightIcon />
                                    <ArrowDropDownIcon />
                                </ToggleButton>
                                <ToggleButton
                                    value="fillColor"
                                    aria-label="select color"
                                    onClick={this.openChooseColor}
                                >
                                    <FormatColorFillIcon
                                        style={{ fill: fillColor }}
                                    />
                                    <ArrowDropDownIcon />
                                </ToggleButton>
                            </ToggleButtonGroup>

                            <Divider
                                flexItem
                                orientation="vertical"
                                className={classes.divider}
                            />
                            {
                                /* close / open polygon mode only for freehand drawing mode. */
                                activeTool === 'freehand' ? (
                                    <ToggleButtonGroup
                                        size="small"
                                        value={closedMode}
                                        onChange={this.changeClosedMode}
                                        className="mb-4"
                                    >
                                        <ToggleButton value="closed">
                                            <ClosedPolygonIcon />
                                        </ToggleButton>
                                        <ToggleButton value="open">
                                            <OpenPolygonIcon />
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                ) : null
                            }
                        </Grid>
                    </Grid>
                    <Button onClick={closeCompanionWindow}>Cancel</Button>
                    <Button variant="contained" color="primary" type="submit">
                        Save
                    </Button>
                </form>
                <Popover
                    open={lineWeightPopoverOpen}
                    anchorEl={popoverLineWeightAnchorEl}
                >
                    <Paper>
                        <ClickAwayListener
                            onClickAway={this.handleCloseLineWeight}
                        >
                            <MenuList autoFocus role="listbox">
                                {[1, 3, 5, 10, 50].map((option, index) => (
                                    <MenuItem
                                        key={option}
                                        onClick={this.handleLineWeightSelect}
                                        value={option}
                                        selected={option === strokeWidth}
                                        role="option"
                                        aria-selected={option === strokeWidth}
                                    >
                                        {option}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Popover>
                <Popover
                    open={colorPopoverOpen}
                    anchorEl={popoverAnchorEl}
                    onClose={this.closeChooseColor}
                >
                    <SketchPicker
                        // eslint-disable-next-line react/destructuring-assignment
                        color={this.state[currentColorType] || {}}
                        onChangeComplete={this.updateStrokeColor}
                    />
                </Popover>
            </CompanionWindow>
        )
    }
}

/** */
const styles = (theme) => ({
    divider: {
        margin: theme.spacing(1, 0.5),
    },
    grouped: {
        '&:first-child': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:not(:first-child)': {
            borderRadius: theme.shape.borderRadius,
        },
        border: 'none',
        margin: theme.spacing(0.5),
    },
    paper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    section: {
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(2),
    },
})

AnnotationCreation.propTypes = {
    annotation: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    canvases: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.string, index: PropTypes.number }),
    ),
    classes: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    closeCompanionWindow: PropTypes.func,
    config: PropTypes.shape({
        annotation: PropTypes.shape({
            adapter: PropTypes.func,
            defaults: PropTypes.objectOf(
                PropTypes.oneOfType([
                    PropTypes.bool,
                    PropTypes.func,
                    PropTypes.number,
                    PropTypes.string,
                ]),
            ),
        }),
    }).isRequired,
    id: PropTypes.string.isRequired,
    receiveAnnotation: PropTypes.func.isRequired,
    windowId: PropTypes.string.isRequired,
}

AnnotationCreation.defaultProps = {
    annotation: null,
    canvases: [],
    closeCompanionWindow: () => {},
}

export default withStyles(styles)(AnnotationCreation)
